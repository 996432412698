import { UnresponsiveCapsizedText } from '@/components/text';
import { VimeoPlayer } from '@/components/vimeo-player';
import { styled } from '@/stitches.config';
import {
  BackgroundBlockLeft,
  BackgroundBlockRight,
  LEFT_ICON_SIZE,
  buttonFocusStyle,
} from '../button-link';
import { Close } from '../icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { keyframes } from '@stitches/react';
import { Play } from '../icons/video/play';

type FullScreenVimeoProps = {
  buttonLabel: string;
  videoId?: string;
};

export function FullScreenVimeo({
  buttonLabel,
  videoId,
}: FullScreenVimeoProps) {
  // We duplicate the content for our hover effect, so we can invert its colours
  const getDuplicateContent = () => {
    return (
      <DuplicateContent>
        <Play width={18} height={18} />
        <UnresponsiveCapsizedText size={2}>
          {buttonLabel}
        </UnresponsiveCapsizedText>
      </DuplicateContent>
    );
  };

  if (!videoId) {
    return null;
  }

  return (
    <DialogPrimitive.Root>
      <StyledTrigger>
        <WatchButton>
          <Play width={18} height={18} />
          <UnresponsiveCapsizedText size={2}>
            {buttonLabel}
          </UnresponsiveCapsizedText>

          <BackgroundBlockLeft aria-hidden="true" iconAlignment="left">
            {getDuplicateContent()}
          </BackgroundBlockLeft>

          <BackgroundBlockRight aria-hidden="true" iconAlignment="left">
            {getDuplicateContent()}
          </BackgroundBlockRight>
        </WatchButton>
      </StyledTrigger>

      <DialogPrimitive.Portal>
        <StyledContent asChild>
          <FullScreenContainer>
            <VimeoPlayer
              videoId={videoId.toString()}
              autoplay="immediate"
              isFixed={true}
            />
            <CloseButtonWrapper>
              <CloseButton>
                <Close
                  style={{ width: 34, height: 34, position: 'relative' }}
                />
              </CloseButton>
              <HoverBlock>
                <Close
                  style={{
                    width: 34,
                    height: 34,
                    position: 'relative',
                  }}
                />
              </HoverBlock>
            </CloseButtonWrapper>
          </FullScreenContainer>
        </StyledContent>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const fadeout = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
});

const LEFT_BLOCK_HOVER_STATE = {
  [`${BackgroundBlockRight}`]: {
    clipPath: `polygon(calc(${LEFT_ICON_SIZE} - 1px) -1px, calc(100% + 1px) -1px, calc(100% + 1px) calc(100% + 1px), calc(${LEFT_ICON_SIZE} - 1px) calc(100% + 1px))`, // rectangle with full height
  },
};

const RIGHT_BLOCK_HOVER_STATE = {
  [`${BackgroundBlockLeft}`]: {
    clipPath: `polygon(-1px -1px, ${LEFT_ICON_SIZE} -1px, ${LEFT_ICON_SIZE} calc(100% + 1px), -1px calc(100% + 1px))`, // rectangle with full height
  },
};

const StyledTrigger = styled(DialogPrimitive.Trigger, {
  '&:hover': {
    ...LEFT_BLOCK_HOVER_STATE,
    ...RIGHT_BLOCK_HOVER_STATE,
  },

  '&:focus-visible': {
    ...buttonFocusStyle,

    ...LEFT_BLOCK_HOVER_STATE,
    ...RIGHT_BLOCK_HOVER_STATE,
  },

  '&:active': {
    background: '$background',
    opacity: 0.8,
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  '&[data-state="open"]': {
    animation: `${fadeIn} 300ms ease-out`,
  },

  '&[data-state="closed"]': {
    animation: `${fadeout} 200ms ease-out`,
  },
});

const FullScreenContainer = styled('div', {
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  inset: 0,
  backgroundColor: '$foreground',
  zIndex: 999,

  video: {
    objectFit: 'contain',
  },

  '&:focus': {
    outline: 'none',
  },
});

const WatchButton = styled('span', {
  color: '$foreground',
  border: '1px solid currentColor',
  padding: '$2 $4 $2 $3',
  display: 'flex',
  gap: '$3',
  alignItems: 'center',
  pointerEvents: 'auto',
  position: 'relative',
  overflow: 'hidden',

  '@bp3': {
    padding: '$3 $4 $3 $3',
  },

  '@bp4': {
    padding: '$3 $5 $3 $4',
  },
});

const DuplicateContent = styled('span', {
  position: 'relative',
  display: 'inline-flex',
  color: '$background',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$2',

  svg: {
    margin: '0 $3 0 $3',
    '@bp3': {
      margin: '0 $3 0 $4',
    },
  },
});

const HoverBlock = styled(DialogPrimitive.Close, {
  backgroundColor: '$foreground',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$3',

  svg: {
    color: '$background',
  },

  clipPath:
    'polygon(0 100%, 100% 100%, 100% calc(100% + 1px), 0 calc(100% + 1px))', // rectangle with no height
  transition: 'clip-path 320ms ease-out',
});

const CloseButton = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$3',
});

const CloseButtonWrapper = styled('div', {
  position: 'absolute',
  top: 0,
  right: 0,
  width: 'fit-content',
  height: 'fit-content',
  backgroundColor: '$background',

  '&:hover': {
    [`${HoverBlock}`]: {
      clipPath:
        'polygon(0 0, 100% 0, 100% calc(100% + 1px), 0 calc(100% + 1px))', // rectangle with full height
    },
  },

  '&:focus-visible': {
    ...buttonFocusStyle,
    outlineOffset: '-2px',
  },

  '&:active': {
    background: '$background',
    opacity: 0.7,
  },
});
